export const images = {
	homeHeader: require("../assets/homeHeader.png"),
	activitiesHeader: require("../assets/activitiesHeader.png"),
	logo: require("../assets/logo.png"),
	menuIcon: require("../assets/menuIcon.png"),
	facebook: require("../assets/facebook.png"),
	instagram: require("../assets/instagram.png"),

	internet: require("../assets/facilities/internet.png"),
	jacuzzi: require("../assets/facilities/jacuzzi.png"),
	sauna: require("../assets/facilities/sauna.png"),
	parking: require("../assets/facilities/parking.png"),
	salt: require("../assets/facilities/salt-sauna.png"),
	bbq: require("../assets/facilities/bbq.png"),
	living: require("../assets/facilities/living-room.png"),
	terrace: require("../assets/facilities/terrace.png"),
	garden: require("../assets/facilities/garden.png"),
	ski: require("../assets/facilities/ski.png"),

	rooms: {
		emerald: require("../assets/rooms/emerald.png"),
		cashmere: require("../assets/rooms/cashmere.png"),
		lagoon: require("../assets/rooms/lagoon.png"),
		roomIcon: require("../assets/rooms/room.svg").default,
		bathroomIcon: require("../assets/rooms/bathroom.svg").default,
		peopleIcon: require("../assets/rooms/person.svg").default,
	},

	activities: {
		jeep: require("../assets/activities/jeep.png"),
		hiking: require("../assets/activities/hiking.png"),
		cycling: require("../assets/activities/cycling.png"),
		ski: require("../assets/activities/ski.png"),
		jeep2: require("../assets/activities/jeep2.png"),
		jeep3: require("../assets/activities/jeep3.png"),
		check: require("../assets/activities/check.png"),

		jeepTablet: require("../assets/activities/tablet/jeep.png"),
		hikingTablet: require("../assets/activities/tablet/hiking.png"),
		skiTablet: require("../assets/activities/tablet/ski.png"),
		jeep2Tablet: require("../assets/activities/tablet/jeep2.png"),
		jeep3Tablet: require("../assets/activities/tablet/jeep3.png"),
	},

	contactImage: require("../assets/contact.png"),
	location: require("../assets/location.png"),
	phone: require("../assets/phone.png"),
	mail: require("../assets/mail.png"),
	arrowLeft: require("../assets/gallery/left-arrow.png"),
	arrowRight: require("../assets/gallery/right-arrow.png"),
	close: require("../assets/close.png"),
}
