import { Suspense } from "react";
import styles from "./hikingStyles.module.css";
import { images } from "../../../constants/images";
import { hikingLocations } from "../../../constants/arrays";
import useWidth from "../../../hooks/useWidth";
import { useTranslation } from "react-i18next";

interface HikingProps {
  title: string;
  image: string;
  imageTablet: string;
  description: string;
  duration: string;
  difficulty: string;
  marking: string;
  number: string;
}

const Hiking = () => {
  const screenWidth = useWidth();
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.firstSection}>
        <div className={styles.imageContainer}>
          <img
            src={
              screenWidth > 900 || screenWidth <= 500
                ? images.activities.hiking
                : images.activities.hikingTablet
            }
            className={styles.firstImage}
          />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title1}>
            {t("activities.hiking.firstSection.title1")}
            <p
              className={styles.title1}
              style={{
                borderBottom: "2px solid rgba(233, 240, 235, 1)",
                width: screenWidth > 500 ? "fit-content" : "100%",
              }}
            >
              {t("activities.hiking.firstSection.title2")}{" "}
              <span className={styles.title2}>
                {t("activities.hiking.firstSection.title3")}
              </span>
            </p>
          </div>

          <div className={styles.description}>
            {t("activities.hiking.firstSection.description")}
            <br />
            <br />
            {t("activities.hiking.firstSection.option1")}
            <br />
            {t("activities.hiking.firstSection.option2")}
          </div>
        </div>
      </div>
      {hikingLocations.map((item: HikingProps, index: number) => (
        <div
          className={styles.gradientBackground}
          style={index % 2 === 1 ? { background: "none" } : {}}
        >
          <div
            className={`${styles.locationContainer} ${
              index % 2 === 1 ? styles.locationContainerAlt : ""
            }`}
          >
            <div
              className={`${styles.locationTextContainer} ${
                index % 2 === 1 ? styles.locationTextAlt : ""
              }`}
            >
              <div
                className={styles.locationTitle}
                style={
                  screenWidth <= 501 && index % 2 === 1
                    ? {
                        borderBottom: "1px solid rgba(233, 240, 235, 1)",
                      }
                    : {}
                }
              >
                {t(`${item.title}`)}
                <span
                  className={styles.titleLine}
                  style={
                    index % 2 === 1
                      ? {
                          borderColor: "rgba(16, 49, 42, 1)",
                        }
                      : {}
                  }
                />
                <img className={styles.locationNumber} src={item.number} />
              </div>
              <div
                className={styles.description}
                style={{ textAlign: "justify" }}
              >
                {t(`${item.description}`)} <br />
                <br />
                {t(`${item.duration}`)} <br />
                {t(`${item.difficulty}`)} <br />
                {t(`${item.marking}`)}
              </div>
            </div>
            <div className={styles.secondImageContainer}>
              <img
                src={screenWidth > 900 ? item.image : item.imageTablet}
                className={styles.locationImage}
                style={
                  index % 2 === 0 && screenWidth > 500
                    ? { marginRight: "-3em" }
                    : {}
                }
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Hiking;
