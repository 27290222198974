import React, { RefObject, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { images } from "../../../constants/images";
import { navigationButtons } from "../../../constants/arrays";
import styles from "./styles.module.css";
import MobileNavigation from "../MobileNavigation/MobileNavigation";
import LangDropdown from "../LangDropdown/LangDropdown";
import useWidth from "../../../hooks/useWidth";

interface Props {
  scroll?: (scrollRef: RefObject<HTMLDivElement>) => void;
  scrollToAbout?: () => void;
  ref?: React.Ref<HTMLDivElement>;
  refs?: RefObject<HTMLDivElement>[];
  setShowMenu: (arg: boolean) => void;
  data: {
    type: string;
    title: string;
    subtitle: string;
    image: string;
  };
}

const Header: React.FC<Props> = ({
  scroll,
  refs,
  scrollToAbout,
  setShowMenu,
  data,
}) => {
  const screenWidth = useWidth();
  const navigation = useNavigate();
  const { t } = useTranslation();
  return (
    <div>
      <div
        className={`${styles.headerImage} ${
          data.type === "activities" ? styles.activitiesHeader : ""
        }`}
      >
        <div className={styles.headerOverlay}>
          {screenWidth > 900 ? (
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div className={styles.dropdownContainer} />
              <div className={styles.navigation}>
                {navigationButtons.map((item: string, index: number) => (
                  <>
                    {index === 3 ? (
                      <img src={images.logo} className={styles.logo} />
                    ) : (
                      <></>
                    )}

                    <a
                      className={styles.buttons}
                      onClick={() =>
                        data.type === "home"
                          ? index === 1 && scrollToAbout
                            ? scrollToAbout()
                            : scroll && refs
                            ? scroll(refs[index])
                            : 0
                          : navigation("/", {
                              state: {
                                ref: index,
                              },
                            })
                      }
                    >
                      {t(`${item}`)}
                    </a>
                  </>
                ))}
              </div>
              <div className={styles.dropdownContainer}>
                <LangDropdown />
              </div>
            </div>
          ) : (
            <MobileNavigation setShowMenu={setShowMenu} />
          )}
          <div className={styles.welcomeContainer}>
            <h1
              className={
                data.type === "activities" && screenWidth <= 900
                  ? styles.activitiesTitle
                  : styles.welcomeTitle
              }
            >
              {t(`${data.title}`)}
            </h1>
            <h3
              className={
                data.type === "home"
                  ? styles.welcomeSubtitle
                  : styles.activitiesSubtitle
              }
            >
              {t(`${data.subtitle}`)}
            </h3>
            <a
              className={styles.contactButton}
              onClick={() =>
                data.type === "home" && scroll && refs
                  ? scroll(refs[refs.length - 1])
                  : navigation("/", {
                      state: {
                        ref: 5,
                      },
                    })
              }
            >
              {t("homepage.header.contactButton")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
