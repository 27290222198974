import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { languages } from "../../../constants/arrays";

const LangDropdown = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(i18n.language);
  const [show, setShow] = useState<boolean>(false);

  const handleLangChange = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang.toLocaleLowerCase());
  };

  return (
    <>
      <a className={styles.container} onClick={() => setShow(!show)}>
        <p>{language.toUpperCase()}</p>
        <img
          src={require("../../../assets/dropdownArrow.png")}
          className={styles.arrow}
        />
      </a>
      {show ? (
        <div className={styles.dropdownContainer}>
          {languages.map((item: string) => (
            <a
              className={styles.buttons}
              onClick={() => {
                handleLangChange(item);
                setShow(false);
              }}
            >
              {item}
            </a>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LangDropdown;
