export const homeHeader = {
	title: "homepage.header.title",
	subtitle: "homepage.header.subtitle",
	type: "home",
	image: "../assets/homeHeader.png",
}

export const activitiesHeader = {
	type: "activities",
	title: "activities.header.title",
	subtitle: "activities.header.subtitle",
	image: "../assets/activitiesHeader.png",
}

export const socialMediaLinks = { 
	facebook: 'https://www.facebook.com/profile.php?id=100089243402699', 
	instagram: 'https://www.instagram.com/casacerbului/'
}