import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Homepage from "./screens/Homepage/Homepage"
import Activities from "./screens/Activities/Activites"
import { Suspense, lazy } from "react"

function App() {
	const HomePage = lazy(() => import("./screens/Homepage/Homepage"))
	return (
		<Suspense fallback={<p>loading...</p>}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route
						path="/activity/atv"
						element={<Activities tab="atv" />}
					/>
					<Route
						path="/activity/ski"
						element={<Activities tab="ski" />}
					/>
					<Route
						path="/activity/hiking"
						element={<Activities tab="hiking" />}
					/>
				</Routes>
			</BrowserRouter>
		</Suspense>
	)
}

export default App
