import styles from "./skiStyles.module.css";
import { images } from "../../../constants/images";
import {
  skilocations,
  cavnicTracks,
  suiorTracks,
} from "../../../constants/arrays";
import useWidth from "../../../hooks/useWidth";
import { useTranslation } from "react-i18next";

interface SkiProps {
  title: string;
  image: string;
  imageTablet: string;
  description: string;
  distance: string;
  number: string;
}

interface TrackProps {
  name: string;
  difficulty: string;
  length: string;
}

interface TrackTable {
  data: TrackProps[];
}

const header = ["activities.ski.track", "activities.ski.difficulty", "activities.ski.length"];

const SkiTrackTable: React.FC<TrackTable> = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        {header.map((item: string) => (
          <div className={styles.tableTitle}>{t(`${item}`)}</div>
        ))}
      </div>
      <div>
        {data.map(
          (item: { name: string; difficulty: string; length: string }) => (
            <div className={styles.tableRow}>
              <span className={styles.tableTitle}>{t(`${item.name}`)}</span>
              <span className={styles.tableTitle}>{t(`${item.difficulty}`)}</span>
              <span className={styles.tableTitle}>{t(`${item.length}`)}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const Ski = () => {
  const screenWidth = useWidth();
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.firstSection}>
        <div className={styles.imageContainer}>
          <img
            src={
              screenWidth > 900 || screenWidth <= 500
                ? images.activities.ski
                : images.activities.skiTablet
            }
            className={styles.firstImage}
          />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title1}> {t("activities.ski.firstSection.title1")}</div>
          <div className={styles.title2}> {t("activities.ski.firstSection.title2")}</div>

          <div className={styles.description}>
         {t("activities.ski.firstSection.description")}
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      {skilocations.map((item: SkiProps, index: number) => (
        <>
          <div className={styles.gradientBackground}>
            <div className={styles.locationContainer}>
              <div className={styles.locationTextContainer}>
                <div className={styles.locationTitle}>
                  {t(`${item.title}`)}
                  <span className={styles.titleLine} />
                  <img className={styles.locationNumber} src={item.number} />
                </div>
                <div
                  className={styles.description}
                  style={{ textAlign: "justify" }}
                >
                  {t(`${item.description}`)} <br />
                  <br />
                  {t(`${item.distance}`)}
                </div>
              </div>
              <div className={styles.secondImageContainer}>
                <img
                  src={screenWidth > 900 ? item.image : item.imageTablet}
                  className={styles.locationImage}
                />
              </div>
            </div>
          </div>
          {index === 0 ? (
            <SkiTrackTable data={cavnicTracks} />
          ) : index === 1 ? (
            <SkiTrackTable data={suiorTracks} />
          ) : (
            <></>
          )}
        </>
      ))}
    </div>
  );
};

export default Ski;
