import styles from "./styles.module.css"
import { contactInfo } from "../../../constants/arrays"
import { useTranslation } from "react-i18next"

interface InfoProps {
	icon: string
	text: string
}

const Contact = () => {
	const { t } = useTranslation()
	return (
		<div className={styles.container}>
			<div className={styles.overlay}>
				<div className={styles.infoContainer}>
					{contactInfo.map((item: InfoProps) => (
						<div className={styles.infoItemContainer}>
							<img src={item.icon} className={styles.infoIcon} />
							<div className={styles.infoText}>{item.text}</div>
						</div>
					))}
				</div>
				<div className={styles.formContainer}>
					<div className={styles.formText}>
						<div className={styles.title}>
							{t("homepage.contact.title")}
						</div>
						<div className={styles.text}>
							{t("homepage.contact.description")}
						</div>
					</div>
					<div className={styles.form}>
						<input
							placeholder={t("homepage.contact.name")}
							className={styles.input}
						/>
						<input
							placeholder={t("homepage.contact.email")}
							type="email"
							className={styles.input}
						/>
						<textarea
							placeholder={t("homepage.contact.message")}
							rows={5}
							className={styles.input}
						/>
						<button className={styles.sendButton}>
							{t("homepage.contact.send")}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Contact
