import styles from "./jeepStyles.module.css";
import { useTranslation } from "react-i18next";
import { images } from "../../../constants/images";
import { atvOptions, jeepOptions } from "../../../constants/arrays";
import useWidth from "../../../hooks/useWidth";

const Jeep = () => {
  const screenWidth = useWidth();
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.firstSection}>
        <div className={styles.imageContainer}>
          <img
            src={
              screenWidth > 900 || screenWidth < 501
                ? images.activities.jeep
                : images.activities.jeepTablet
            }
            className={styles.firstImage}
          />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title1}>
            {t("activities.jeep.firstSection.title1")}
          </div>
          <div className={styles.title2}>
            {t("activities.jeep.firstSection.title2")}
          </div>

          <div className={styles.description}>
            {t("activities.jeep.firstSection.description")} <br />
            <br />
            {t("activities.jeep.firstSection.difficulty")} <br />
            <br />
            <br />
            {t("activities.jeep.firstSection.option")}
          </div>
        </div>
      </div>
      <div className={styles.gradientBackground}>
        <div className={styles.secondSection}>
          <div className={styles.secondTextContainer}>
            <div className={styles.secondTitle}>
              {t("activities.jeep.secondSection.title")}
            </div>
            <div
              className={styles.description}
              style={{ textAlign: "justify" }}
            >
              {t("activities.jeep.secondSection.description")} <br />
              <br />
            </div>
            <div className={styles.options}>
              {atvOptions.map((item: string) => (
                <div className={styles.optionContainer}>
                  <img
                    src={images.activities.check}
                    className={styles.optionImage}
                  />
                  <div className={styles.optionText}>{t(`${item}`)}</div>
                </div>
              ))}
            </div>
            <div>
              <br />
              {t("activities.jeep.priceTitle")}
            </div>
            <div className={styles.price}>150 {t("activities.jeep.price")}</div>
          </div>
          <div className={styles.secondImageContainer}>
            <img
              src={
                screenWidth > 900
                  ? images.activities.jeep2
                  : images.activities.jeep2Tablet
              }
              className={styles.firstImage}
            />
          </div>
        </div>
      </div>
      <div className={styles.firstSection}>
        <div className={styles.imageContainer}>
          <img
            src={
              screenWidth > 900
                ? images.activities.jeep3
                : images.activities.jeep3Tablet
            }
            className={styles.thirdImage}
          />
          <div className={styles.frame} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.thirdTitle}>
            {t("activities.jeep.thirdSection.title")}
          </div>
          <div className={styles.description} style={{ textAlign: "justify" }}>
            {t("activities.jeep.thirdSection.description")} <br />
            <br />
          </div>
          {jeepOptions.map((item: string) => (
            <div className={styles.optionContainer}>
              <img
                src={images.activities.check}
                className={styles.optionImage}
              />
              <div className={styles.optionText}>{t(`${item}`)}</div>
            </div>
          ))}
          <div>
            <br />
            {t("activities.jeep.priceTitle")}
          </div>
          <div className={styles.priceJeep}>
            200 {t("activities.jeep.price")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jeep;
