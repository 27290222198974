import React, { RefObject } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.css"
import { images } from "../../../constants/images"
import { navigationButtons } from "../../../constants/arrays"
import { socialMediaLinks } from "../../../constants/data"

interface Props {
	scroll?: (scrollRef: RefObject<HTMLDivElement>) => void
	scrollToAbout?: () => void
	refs?: RefObject<HTMLDivElement>[]
	data: {
		type: string
		title: string
		subtitle: string
		image: string
	}
}

const Footer: React.FC<Props> = ({ scroll, scrollToAbout, refs, data }) => {
	const { t } = useTranslation()
	const navigation = useNavigate()
	return (
		<div className={styles.container}>
			<img alt='logo' src={images.logo} className={styles.logo} />
			<div className={styles.socialMediaContainer}>
				<a href={socialMediaLinks.facebook} target="_blank" rel="noreferrer">
					<img alt='facebook' className={styles.facebook} src={images.facebook} />
				</a>
				<a href={socialMediaLinks.instagram} target="_blank" rel="noreferrer">
					<img alt='instagram' className={styles.instagram} src={images.instagram} />
				</a>
			</div>
			<div className={styles.breakLine} />
			<div className={styles.buttonsContainer}>
				{navigationButtons.map((item: string, index: number) => (
					<a
						className={styles.buttons}
						onClick={() =>
							data.type === "home"
								? index === 1 && scrollToAbout
									? scrollToAbout()
									: scroll && refs
									? scroll(refs[index])
									: 0
								: navigation("/", {
										state: {
											ref: index,
										},
								  })
						}
					>
						{t(`${item}`)}
					</a>
				))}
			</div>
		</div>
	)
}

export default Footer
