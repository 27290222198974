import { images } from "./images"

export const languages = ["RO", "EN", "DE", "FR", "HU"]

export const navigationButtons = [
	"homepage.menuButtons.home",
	"homepage.menuButtons.aboutUs",
	"homepage.menuButtons.accommodation",
	"homepage.menuButtons.activities",
	"homepage.menuButtons.gallery",
	"homepage.menuButtons.contact",
]

export const facilities = [
	{
		icon: images.internet,
		text: "homepage.facilities.internet",
	},
	{
		icon: images.jacuzzi,
		text: "JACUZZI",
	},
	{
		icon: images.sauna,
		text: "homepage.facilities.sauna",
	},
	{
		icon: images.parking,
		text: "homepage.facilities.parking",
	},
	{
		icon: images.salt,
		text: "homepage.facilities.salt",
	},
	{
		icon: images.bbq,
		text: "homepage.facilities.bbq",
	},
	{
		icon: images.ski,
		text: "homepage.facilities.ski",
	},
	{
		icon: images.living,
		text: "homepage.facilities.living",
	},
	{
		icon: images.terrace,
		text: "homepage.facilities.terrace",
	},
	{
		icon: images.garden,
		text: "homepage.facilities.garden",
	},
]

export const rooms = [
	{
		title: "homepage.rooms.cashmere.title",
		image: images.rooms.cashmere,
		description: "homepage.rooms.cashmere.description",
		noOfPeople: "2",
		roomType: "homepage.rooms.room",
		facilities: [
			"homepage.rooms.minibar",
			"homepage.rooms.tv",
			"homepage.rooms.hygiene",
			"homepage.rooms.shower",
			"homepage.rooms.mountainView",
			"homepage.rooms.dryer",
			"homepage.rooms.bathrobe",
		],
		actionButton: "homepage.activities.reserveButton",
		themeColor: "rgba(205, 154, 73, 1)",
	},
	{
		title: "homepage.rooms.emerald.title",
		image: images.rooms.emerald,
		description: "homepage.rooms.emerald.description",
		noOfPeople: "2",
		roomType: "homepage.rooms.room",
		facilities: [
			"homepage.rooms.minibar",
			"homepage.rooms.tv",
			"homepage.rooms.hygiene",
			"homepage.rooms.shower",
			"homepage.rooms.mountainView",
			"homepage.rooms.dryer",
			"homepage.rooms.bathrobe",
		],
		actionButton: "homepage.activities.reserveButton",
		themeColor: "rgba(16, 49, 42, 1)",
	},
	{
		title: "homepage.rooms.lagoon.title",
		image: images.rooms.lagoon,
		description: "homepage.rooms.lagoon.description",
		noOfPeople: "2-4",
		roomType: "homepage.rooms.room",
		facilities: [
			"homepage.rooms.minibar",
			"homepage.rooms.tv",
			"homepage.rooms.hygiene",
			"homepage.rooms.shower",
			"homepage.rooms.gardenView",
			"homepage.rooms.dryer",
			"homepage.rooms.bathrobe",
			"homepage.rooms.balcony",
		],
		actionButton: "homepage.activities.reserveButton",
		themeColor: "rgba(107, 121, 106, 1)",
	},
]

export const activities = [
	{
		image: images.activities.jeep,
		title: "homepage.activities.jeep.title",
		description: "homepage.activities.jeep.description",
		tag: "atv",
	},
	{
		image: images.activities.hiking,
		title: "homepage.activities.hiking.title",
		description: "homepage.activities.hiking.description",
		tag: "hiking",
	},
	{
		image: images.activities.ski,
		title: "homepage.activities.ski.title",
		description: "homepage.activities.ski.description",
		tag: "ski",
	},
]

export const contactInfo = [
	{
		icon: images.location,
		text: "Strada Dragoș Vodă 113 A,\nBaia Sprie",
	},
	{
		icon: images.phone,
		text: "+40 742 228 792",
	},
	{
		icon: images.mail,
		text: "casacerbuluimm@gmail.com",
	},
]

export const photoGallery = [
	require("../assets/gallery/photo2.png"),
	require("../assets/gallery/photo2.png"),
	require("../assets/gallery/photo2.png"),
	require("../assets/gallery/photo2.png"),
	require("../assets/gallery/photo2.png"),
	require("../assets/gallery/photo2.png"),
	require("../assets/gallery/photo2.png"),
]

export const atvOptions = [
	"activities.jeep.options.helmet",
	"activities.jeep.options.gloves",
	"activities.jeep.options.fuel",
	"activities.jeep.options.guide",
	"activities.jeep.options.stops",
]

export const jeepOptions = [
	"activities.jeep.options.fuel",
	"activities.jeep.options.stops",
	"activities.jeep.options.guide",
]

export const tabs = [
	{
		icon: require("../assets/activities/tabs/jeep.png"),
		iconSelected: require("../assets/activities/tabs/jeepSelected.png"),
		text: "activities.tabs.jeep",
		tag: "atv",
	},
	{
		icon: require("../assets/activities/tabs/hiking.png"),
		iconSelected: require("../assets/activities/tabs/hikingSelected.png"),
		text: "activities.tabs.hiking",
		tag: "hiking",
	},
	{
		icon: require("../assets/activities/tabs/ski.png"),
		iconSelected: require("../assets/activities/tabs/skiSelected.png"),
		text: "activities.tabs.ski",
		tag: "ski",
	},
]

export const hikingLocations = [
	{
		title: "activities.hiking.lake.title",
		image: require("../assets/activities/hiking1.png"),
		imageTablet: require("../assets/activities/tablet/hiking1.png"),
		description: "activities.hiking.lake.description",
		duration: "activities.hiking.lake.duration",
		difficulty: "activities.hiking.lake.difficulty",
		marking: "activities.hiking.lake.marking",
		number: require("../assets/activities/01.png"),
	},
	{
		title: "activities.hiking.suior.title",
		image: require("../assets/activities/hiking2.png"),
		imageTablet: require("../assets/activities/tablet/hiking2.png"),
		description: "activities.hiking.theCrest.description",
		duration: "activities.hiking.theCrest.duration",
		difficulty: "activities.hiking.theCrest.difficulty",
		marking: "activities.hiking.theCrest.marking",
		number: require("../assets/activities/02.png"),
	},
	{
		title: "activities.hiking.suior.title",
		image: require("../assets/activities/hiking3.png"),
		imageTablet: require("../assets/activities/tablet/hiking3.png"),
		description: "activities.hiking.suior.description",
		duration: "activities.hiking.suior.duration",
		difficulty: "activities.hiking.suior.difficulty",
		marking: "activities.hiking.suior.marking",
		number: require("../assets/activities/03.png"),
	},
	{
		title: "activities.hiking.ignis.title",
		image: require("../assets/activities/hiking4.png"),
		imageTablet: require("../assets/activities/tablet/hiking4.png"),
		description: "activities.hiking.ignis.description",
		duration: "activities.hiking.ignis.duration",
		difficulty: "activities.hiking.ignis.difficulty",
		marking: "activities.hiking.ignis.marking",
		number: require("../assets/activities/04.png"),
	},
]

export const skilocations = [
	{
		title: "activities.ski.cavnic.title",
		image: require("../assets/activities/ski1.png"),
		imageTablet: require("../assets/activities/tablet/ski1.png"),
		description:"activities.ski.cavnic.description",
		distance:"activities.ski.cavnic.distance",
		number: require("../assets/activities/01.png"),
	},
	{
		title: "activities.ski.suior.title",
		image: require("../assets/activities/ski2.png"),
		imageTablet: require("../assets/activities/tablet/ski2.png"),
		description:"activities.ski.suior.description",
		distance:"activities.ski.suior.distance",
		number: require("../assets/activities/02-ski.png"),
	},
]

export const cavnicTracks = [
	{
		name: "Roata 1",
		difficulty: "activities.ski.medium",
		length: "920 m",
	},
	{
		name: "Roata 2",
		difficulty: "activities.ski.easy",
		length: "1030 m",
	},
	{
		name: "Rainer 1 (Roata 3)",
		difficulty: "activities.ski.hard",
		length: "800 m",
	},
	{
		name: "Rainer 2 (Roata 4)",
		difficulty: "activities.ski.hard",
		length: "1000 m",
	},
	{
		name: "Pârtia Albastră",
		difficulty: "activities.ski.easyMedium",
		length: "2250 m",
	},
	{
		name: "Pârtia Albastră (Roata 2)",
		difficulty: "activities.ski.easy",
		length: "160 m",
	},
	{
		name: "Rainer 1 (Roata 1)",
		difficulty: "activities.ski.easy",
		length: "140 m",
	},
]

export const suiorTracks = [
	{
		name: "Șuior 1",
		difficulty: "activities.ski.medium",
		length: "1500 m",
	},
	{
		name: "Șuior 2",
		difficulty: "activities.ski.medium",
		length: "1200 m",
	},
	{
		name: "Șuior 3",
		difficulty: "activities.ski.medium",
		length: "350 m",
	},
	{
		name: "Începători",
		difficulty: "activities.ski.easy",
		length: "150 m",
	},
	{
		name: "Moski",
		difficulty: "activities.ski.hard",
		length: "600 m",
	},
	{
		name: "Nocturnă",
		difficulty: "activities.ski.medium",
		length: "1500 m",
	},
]
