import React, { useEffect, useState } from "react";

import styles from "./styles.module.css";
import Header from "../../components/Homepage/Header/Header";
import { Menu } from "../../components/Homepage/MobileNavigation/MobileNavigation";
import { activitiesHeader } from "../../constants/data";
import { tabs } from "../../constants/arrays";
import Contact from "../../components/Homepage/Contact/Contact";
import Footer from "../../components/Homepage/Footer/Footer";
import Jeep from "../../components/Activities/Tabs/Jeep";
import Hiking from "../../components/Activities/Tabs/Hiking";
import Ski from "../../components/Activities/Tabs/Ski";
import { useTranslation } from "react-i18next";

interface Props {
  tab: string;
}

const Activities: React.FC<Props> = ({ tab }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(tab);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 600);
  }, []);

  return (
    <div className={styles.container}>
      {showMenu ? (
        <Menu setShowMenu={setShowMenu} data={activitiesHeader} />
      ) : (
        <></>
      )}
      <Header setShowMenu={setShowMenu} data={activitiesHeader} />
      <div>
        <div className={styles.tabsRibbon}>
          <div className={styles.contentContainer}>
            {tabs.map(
              (item: {
                icon: string;
                iconSelected: string;
                text: string;
                tag: string;
              }) => (
                <a
                  className={`${styles.tabContainer} ${
                    selectedTab === item.tag ? styles.tabContainerSelected : ""
                  }`}
                  onClick={() => setSelectedTab(item.tag)}
                >
                  <img
                    className={styles.icon}
                    src={
                      selectedTab === item.tag ? item.iconSelected : item.icon
                    }
                  />
                  <div
                    className={`${styles.tabText} ${
                      selectedTab === item.tag ? styles.tabTextSelected : ""
                    }`}
                  >
                    {t(`${item.text}`)}
                  </div>
                </a>
              )
            )}
          </div>
        </div>
        {selectedTab === "atv" ? (
          <Jeep />
        ) : selectedTab === "hiking" ? (
          <Hiking />
        ) : (
          <Ski />
        )}
      </div>
      <Contact />
      <Footer data={activitiesHeader} />
    </div>
  );
};

export default Activities;
