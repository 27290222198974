import { useEffect, useState } from "react"

const useWidth = () => {
	const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

	const getWidth = () => setScreenWidth(window.innerWidth)

	useEffect(() => {
		window.addEventListener("resize", getWidth)
	}, [])

	return screenWidth
}

export default useWidth
